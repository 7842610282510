a:link,
a:visited {
  color: rgb(2, 116, 182);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.panel {
  width: 100%;
  height: 5rem;
  max-height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0%;
  background-color: rgb(40, 54, 82);
}

.buttons {
  width: 15rem;
  max-width: 90%;
  height: 50%;
  display: flex;
  position: absolute;
  bottom: 20%;
}

.button {
  flex-grow: 1;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  border: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.navigation-track {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(57, 81, 95);
  cursor: pointer;
}

.progress {
  width: 0%;
  background-color: rgb(2, 116, 182);
}

.thumb {
  width: 1rem;
  height: 100%;
  float: right;
  background-color: black;
}

.navigation-track:hover {
  height: 100% !important;
}

.navigation-track:hover .progress {
  height: 120% !important;
}

.info {
  width: 30rem;
  max-width: 90%;
  height: 10rem;
  max-height: 40%;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.popup {
  padding: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: rgb(40, 54, 82);
}

.button--info {
  width: 10%;
  height: 20%;
  position: absolute;
  right: 0;
  top: 5%;
}
