.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
}

.player {
  width: 100%;
  height: 100%;
  object-fit: contain;
  image-rendering: pixelated;
}
